<template>
    <div class="admin-application-filter">
        <div
            v-for="item in filterItems"
            :key="item.key || 'search-category'"
            class="admin-application-filter__item"
            :class="{
                'admin-application-filter__spacer': item.type === 'spacer'
            }"
        >

            <!-- Label -->
            <div v-if="item.labeled" class="admin-application-filter__label">
                {{ $t(item.name) }}
            </div>

            <!-- Text -->
            <div v-if="item.type === 'text'" class="admin-application-filter__text">
                <span v-show="item.visible">{{ getTranslationOrKey(item.name) }}</span>
            </div>

            <!-- Select -->
            <AppSelect
                v-if="item.type === 'drop'"
                :value="filter[item.key]"
                class="admin-application-filter__drop"
                :class="{['admin-application-filter__drop--size-' + item.size]: item.size}"
                :items="item.items"
                item-value="value"
                item-name="name"
                :theme="item.theme || 'gray'"
                size="medium"
                :placeholder="item.name"
                @input="onInput(item.key, $event)"
            />

            <!-- search form with select -->
            <div v-else-if="item.type === 'search-category'" class="admin-application-filter__search">
                <SearchForm
                    :value="filter[filter.categoryInternal]"
                    :type="filter.categoryInternal"
                    :items="item.items" fixed theme="application"
                    @type="onTypeInput"
                    @input="delay(() => onInput(filter.categoryInternal, $event))"
                />
            </div>

            <!-- Switch -->
            <AppSwitch
                v-else-if="item.type === 'switch'"
                :value="filter[item.key]"
                theme="secondary"
                @input="onInput(item.key, $event)"
            />

            <!-- Button -->
            <template v-else-if="item.type === 'button'">
                <AppButton
                    v-if="item.visible !== false"
                    class="admin-application-filter__button admin-application-filter__item"
                    :theme="item.theme || 'success-flat'"
                    @click="onClick(item.key)"
                >
                    <AppIcon v-if="item.icon" class="mr-10" :icon="item.icon"/>
                    {{ getTranslationOrKey(item.name) }}
                </AppButton>
            </template>

        </div>

    </div>
</template>

<script>
import SearchForm from "../partials/SearchForm";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
import AppSelect from "../partials/AppSelect";
import ListUtils from "../../mixins/ListUtils";
import AppSwitch from "../partials/AppSwitch";
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
    name: 'ApplicationFilter',
    mixins: [ListUtils, translationExists],
    components: {AppSwitch, AppSelect, AppIcon, AppButton, SearchForm},
    props: {
        filterItems: Array,
        filter: Object
    },
    mounted() {
        const searchCategory = this.filterItems.find(f => f.type === 'search-category');
        if(searchCategory && !this.filter.categoryInternal) {
            this.onTypeInput(searchCategory.items[0].value);
        }
    },
    methods: {
        onInput(key, value) {
            this.$emit('filter', key, value);
        },
        onClick(key) {
            this.$emit('click', key);
        },
        onTypeInput($event) {
            this.onInput('categoryInternal', $event);
            clearTimeout(this.delayTimeout);
        },
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.admin-application-filter {
    display: flex;
    &__item {
        display: flex;
        align-items: center;
        margin-left: 20px !important;
        margin-right: 0 !important;
        &:first-child {
            margin-left: 0 !important;
        }
    }
    &__label {
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
        margin-right: 12px;
    }
    &__text {
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
    }
    &__search {
        width: 528px;
        margin-right: 20px;
    }
    &__drop {
        width: 244px;
        &--size-small {
            width: 168px;
        }
    }
    &__spacer {
        flex-grow: 1;
        width: 0;
    }
    &__button {
        padding-left: 32px !important;
        padding-right: 32px !important;
        margin-right: 20px;
        svg {
            width: 18px;
            height: 18px;
        }
        &--last {
            margin-right: 0;
            margin-left: auto;
        }
    }
}
</style>
