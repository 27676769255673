<template>
    <div class="admin-application-header mb-28">
        <div class="admin-application-header__heading">{{ $t(name) }}</div>
        <div v-if="!disableCount" class="admin-application-header__sub">{{ $t("common.table.found") }}: {{ count }}</div>
        <AppButton v-if="add" class="admin-application-header__button" theme="success-flat" @click="$emit('click', 'add')">
            {{ $t("common.add") }}
        </AppButton>
    </div>
</template>
<script>
import AppButton from "../partials/AppButton";
export default {
    name: 'ApplicationHeader',
    components: {AppButton},
    props: {
        name: String,
        count: Number,
        add: Boolean,
        disableCount: Boolean
    },
}
</script>
