import {EventEmitter} from "./ChatController";
import app from "../main";
import {del, get, post, put} from "../helpers/api";
import ConfirmController from "@/services/ConfirmController";

export class ValidatorData {
    #messages = {};
    #model;
    #rules;

    constructor(rules, model) {
        // rules - [[ruleName, ruleOption]]
        this.#rules = rules;
        this.#model = model;
    }
    setNewModel(newModel) {
        this.#model = newModel;
    }
    validate() {
        const data = this.#model.getValues ? this.#model.getValues() : {...this.#model};
        const messages = {};
        let valid = true;

        this.#rules.forEach(([key, rules]) => {
            messages[key] = [];
            if(key in data) {
                rules.forEach(([ruleKey, ruleOption]) => {
                    const value = data[key];
                    const message = ValidatorData.validators[ruleKey](value, ruleOption, data);
                    if(message) {
                        valid = false;
                        messages[key].push(message);
                    }
                })
            }
        })

        this.#messages = messages;
        return valid;
    }
    clear() {
        this.#messages = {};
    }
    getMessages() {
        return this.#messages;
    }
    static validators = {
        required(value) {
            return value === '' || value === null || value === undefined || value === false || Array.isArray(value) && !value.length ? 'Поле обязательно для заполнения' : null;
        },
        notRequiredIf(value, otherModelKey, model) {
            const result = this.required(model[otherModelKey]);
            return result ? this.required(value) : null;
        },
        requiredIf(value, otherModelKey, model) {
            const result = this.required(model[otherModelKey]);
            return !result ? this.required(value) : null;
        }
    }
}
export class ModalData extends EventEmitter {
    #value = {};
    #defaultEntries;
    constructor(entries) {
        super(['update']);
        this.#defaultEntries = entries;
        this.reset();
    }
    setNewDefaultValues(defaultEntries) {
        this.#defaultEntries = defaultEntries;
    }
    update(key ,value) {
        this.#value[key] = value;
        this.emit('update', [key]);
    }
    getValue(key) {
        return this.#value[key];
    }
    getValues() {
        return {...this.#value};
    }
    getKeys() {
        return Object.keys(this.#value);
    }
    reset() {
        const keys = [];
        for(const entry of this.#defaultEntries) {
            keys.push(entry[0]);
            this.#value[entry[0]] = entry[1];
        }
        this.emit('update', keys);
    }
}
export class RenderInputs extends EventEmitter {
    #rendered = [];
    #inputs;
    #model;
    constructor(list, model) { // list - {name: string, key: string}[]
        super(['update']);
        this.#inputs = [...list];
        this.#model = model;
        this.update();
    }
    update() {
        const model = this.#model.getValues();
        const inputs = [];
        for(let k of this.#inputs) {
            if(!k.visible_if || k.visible_if && model[k.visible_if]) {
                inputs.push(k);
            }
        }
        this.#rendered = inputs;
        this.emit('update');
    }
    edit(data) {
        const idx = this.#inputs.findIndex(input => data.key === input.key);
        if(idx > -1) {
            this.#inputs.splice(idx, 1, {
                ...this.#inputs[idx],
                ...data
            });
        } else {
            this.#inputs.push(data);
        }
        this.update();
    }
    getInputs() {
        return this.#rendered;
    }
}
// Контроль создания полей формы
export class FormInputElement {
    constructor(key, name, value, type, items = null, rules = [], otherOptions = {}) {
        this.key = key;
        this.name = name;
        this.value = value;
        this.type = type;
        this.items = items;
        this.rules = rules;
        this.resetOn = otherOptions.resetOn;
        this.dependsOn = otherOptions.dependsOn;

        this.otherRenderData = {
            ...otherOptions
        }
        delete this.otherRenderData.resetOn;
        delete this.otherRenderData.dependsOn;
    }
    getModelData() {
        return [this.key, this.value];
    }
    getRenderData() {
        return {
            key: this.key,
            name: this.name,
            type: this.type,
            items: this.items,
            visible_if: this.dependsOn,
            ...this.otherRenderData
        }
    }
    getValidatorData() {
        return [this.key, this.rules];
    }
    flatten() {
        return {...this};
    }
}
const unpackAndCall = (whatUnpack, whatCall, fullyFlatten) => {
    const a = [];
    whatUnpack.forEach(i => {
        const model = i[whatCall]();
        if(Array.isArray(fullyFlatten ? model : model[0])) {
            a.push(...model);
        } else {
            a.push(model);
        }
    });
    return a;
}
export class FormInputList extends FormInputElement {
    constructor(key, name, value, type, items, rules = null, resetOn = {}) {
        super(key, name, value, type, items, [], resetOn);
    }
    getModelData() {
        return unpackAndCall(this.items, 'getModelData');
    }
    getRenderData() {
        const r = super.getRenderData();
        if(r.items) {
            r.items = r.items.map(i => i.getRenderData());
        }
        return r;
    }
    getValidatorData() {
        return unpackAndCall(this.items, 'getValidatorData');
    }
    flatten() {
        return unpackAndCall(this.items, 'flatten', true);
    }
}

// Контроллер для формы
export class ModalFormController extends EventEmitter {
    #watchValidation = false;
    #elementsData
    #model
    #validator
    #renderInputs

    constructor(formInputList) {
        super(['input', 'layout', 'validate']);

        if(formInputList) {
            this.setFormInputList(formInputList);
        }
    }

    includes(key) {
        return this.#elementsData.findIndex(e => e.key === key) > -1;
    }

    #onModelUpdate = (keys) => {
        this.emit('input', keys);
        this.#renderInputs.update();
        if(this.#watchValidation) {
            this.validate();
        }
    }
    #onRenderUpdate = () => {
        this.emit('layout');
    }

    resetFormInputList() {
        this.#model.off('update', this.#onModelUpdate);
        this.#renderInputs.off('update', this.#onRenderUpdate);
    }
    setFormInputList(formInputList) {
        this.#elementsData = formInputList.flatten();
        this.#model = new ModalData(formInputList.getModelData());
        this.#validator = new ValidatorData(formInputList.getValidatorData(), this.#model);
        this.#renderInputs = new RenderInputs(formInputList.getRenderData().items, this.#model);

        this.#model.on('update', this.#onModelUpdate);
        this.#renderInputs.on('update', this.#onRenderUpdate);
    }

    getRenderElements() {
        return this.#renderInputs.getInputs();
    }
    setRenderElement(renderElement) {
        this.#renderInputs.edit(renderElement);
    }

    getModelData() {
        return this.#model.getValues();
    }
    getErrorMessages() {
        return this.#validator.getMessages();
    }

    input(key, value) {
        // Сбрасываем значения при изменении полей. Пока работает только для логических значений. Неизвестно, как будет себя вести при обычных элементах
        const el = this.#elementsData.find(e => e.resetOn === key);
        const keys = [];
        if(el) {
            this.#model.update(el.key, el.value);
            keys.push(el.key);
        }
        this.#model.update(key, value);
        this.emit('input', keys);
        if(this.#watchValidation) {
            this.validate();
        }
    }
    reset() {
        this.#watchValidation = false;
        this.#model.reset();
        this.#validator.clear();
        this.#renderInputs.update();
        this.emit('input', this.#model.getKeys());
        this.emit('validate');
    }
    // Управление валидацией
    validate() {
        const result = this.#validator.validate();
        this.#watchValidation = true;
        this.emit('validate');
        return result;
    }
}
class DummyFormController extends EventEmitter{
    constructor() {
        super(['input', 'layout', 'validate']);
    }
    includes(key) {}
    getRenderElements() {}
    setRenderElement(renderElement) {}
    getModelData() {}
    getErrorMessages() {}
    input(key, value) {}
    reset() {}
    validate() {}
}

export class ModalController extends EventEmitter {
    visible = false;
    link = null;
    #getLink = null;
    #timer = null;
    constructor() {
        super(['update']);
    }
    setLink(getLink) {
        this.#getLink = getLink;
    }
    open() {
        if(this.#timer) {
            clearTimeout(this.#timer);
        }
        this.visible = true;
        this.emit('update', ['visible']);
    }
    close() {
        const modal = this.#getLink && this.#getLink();
        const c = () => {
            this.#timer = null;
            this.visible = false;
            this.emit('update', ['visible']);
        }
        if(modal) {
            modal.closeModal();
            this.timer = setTimeout(c, 200);
        } else {
            c();
        }
    }
}
class DummyModalController extends EventEmitter{
    visible = false;
    link = null;
    constructor() {
        super(['update']);
    }
    setLink() {}
    open() {}
    close() {}
}

// Управление фильтрами, в основном обновление, сохраненние и восстановление фильтров из адресной строки
export class FilterControl {
    #defaultFilter;
    #filter;
    constructor(defaultFilter) {
        this.#defaultFilter = {
            ...defaultFilter
        };
        this.#filter = {
            ...defaultFilter
        };
    }
    load() {
        // Считывание фильтра из адресной строки
        this.restore();
        const query = {...app.$route.query};
        Object.entries(query).forEach(([key, value]) => {
            const defaultValue = this.#defaultFilter[key];
            if(typeof defaultValue === 'number' || defaultValue === null) {
                if(!isNaN(+value)) {
                    this.setValue(key, +value);
                } else {
                    console.error('getFilterFromUrl, Не удается восстановить', key, value);
                }
            } else if(typeof defaultValue === 'string' && value) {
                this.setValue(key, decodeURIComponent(value));
            } else if(Array.isArray(defaultValue) && value) {
                this.setValue(key, decodeURIComponent(value).split(',').map(v => +v));
            }
        });
    }
    get defaultFilter() {
        return {...this.#defaultFilter};
    }
    setValue(key, value) {
        this.#filter[key] = (value !== undefined && value !== null) ? value : this.#defaultFilter[key];
    }
    setValueAndSave(key ,value) {
        this.setValue(key, value);
        app.$router.push('?' + this.toString());
    }
    restore() {
        Object.entries(this.#defaultFilter).forEach(p => this.setValue(...p));
    }
    includes(key) {
        return key in this.#filter;
    }
    getValue(key) {
        return this.#filter[key];
    }
    getValues() {
        return {...this.#filter};
    }
    getKeysList() {
        return Object.keys(this.#filter)
    }
    getStringFilter(filter) {
        let f = Object.entries(filter).filter(([, value]) => Array.isArray(value) ? value.length : value != null && value !== '');
        return f.reduce((acc, [key, value]) => {
            value = encodeURIComponent(Array.isArray(value) ? value.join(',') : value);
            return acc ? `${acc}&${key}=${value}` : key + '=' + value;
        }, '');
    }
    toString() {
        return this.getStringFilter(this.#filter);
    }
}

// Общее управление страницей
export class ScriptPage extends EventEmitter {
    #contentData = {
        loading: false,
        createLoading: false,
        count: 0,
        list: []
    };
    #filterControl;
    #contentLoader;
    #formController;
    #modalController;

    crudErrorMessage = 'errors.somethingWentWrong' // Here is the key that is passed to the arguments in i18n.t()

    constructor(contentLoader, defaultFilter, formController = new DummyFormController(), modalController = new DummyModalController(), otherOptions = {}) {
        super(['input']);

        this.#contentLoader = contentLoader;
        this.#filterControl = new FilterControl(defaultFilter);

        this.#formController = formController;

        formController.on('input', this.onFormAction.bind(this, 'input'));
        formController.on('layout', this.onFormAction.bind(this, 'layout'));
        formController.on('validate', this.onFormAction.bind(this, 'validate'));

        this.#modalController = modalController;
        this.onModalVisibility = this.onModalVisibility.bind(this);
        this.#modalController.on('update', this.onModalVisibility);

        if(otherOptions.crudErrorMessage) {
            this.crudErrorMessage = otherOptions.crudErrorMessage;
        }
    }

    onFormAction(type) {
        const keys = [];
        let key;
        switch(type) {
            case 'input': key = 'formModel'; break;
            case 'layout': key = 'formRenderElements'; break;
            case 'validate': key = 'formErrorMessages'; break;
        }
        keys.push(key);
        this.emit('input', keys);
    }
    onModalVisibility() {
        this.emit('input', ['modalVisible']);
    }

    // Управление фильтрами
    setFilterValueAndFetch(key, value) {
        if(key === 'categoryInternal') {
            const old = this.getValue('categoryInternal');
            this.#filterControl.setValue(old, '');
            this.#filterControl.setValue('categoryInternal', value);
            this.emit('input', ['categoryInternal', old]);
            return;
        }
        this.setFilterValue(key, value);
        this.fetch();
    }
    setFilterValuesAndFetch(filter) {
        const keys = Object.keys(filter);
        keys.forEach(key => {
            this.#filterControl.setValue(key, filter[key]);
        });
        this.#filterControl.setValueAndSave('skip', 0);
        this.emit('input', [...keys, 'skip']);
        this.fetch();
    }
    setFilterValue(key, value) {
        if(key !== 'skip' && key !=='categoryInternal') {
            this.#filterControl.setValue('skip', 0);
            this.emit('input', ['skip']);
        }
        this.#filterControl.setValueAndSave(key, value);
        this.emit('input', [key]);
    }
    loadFilters() {
        this.#filterControl.load();
        const keys = Object.keys(this.#filterControl.getValues());
        this.emit('input', keys);
    }
    getFilterString() {
        return this.#filterControl.toString();
    }
    getFilterStringRequest() {
        const filter = this.#filterControl.getValues();
        if('categoryInternal' in filter) {
            delete filter.categoryInternal;
        }
        return this.#filterControl.getStringFilter(filter);
    }

    getValue(key) {
        if(this.#filterControl.includes(key)) {
            return this.#filterControl.getValue(key);
        } else if(key in this.#contentData) {
            return this.#contentData[key];
        } else if(key === 'modalVisible') {
            return this.#modalController.visible;
        } else if(key === 'formModel') {
            return this.#formController.getModelData();
        } else if(key === 'formErrorMessages') {
            return this.#formController.getErrorMessages();
        } else if(key === 'formRenderElements') {
            return this.#formController.getRenderElements();
        }
    }
    getValues() {
        return {
            ...this.#filterControl.getValues(),
            ...this.#contentData,
            modalVisible: this.#modalController.visible,
            formModel: this.#formController.getModelData(),
            formErrorMessages: this.#formController.getErrorMessages(),
            formRenderElements: this.#formController.getRenderElements()
        }
    }

    openModalAndUpdateForm(model) {
        this.#formController.reset();
        if(model) {
            Object.entries(model).forEach(([key, value]) => {
                this.#formController.input(key, value);
            });
        }
        this.#modalController.open();
    }

    setFormRenderElement(data, key) {
        const element = this.getValue('formRenderElements').find(e => e.key === key);
        let replace;
        if(element) {
            replace = Object.assign(element, data);
        } else {
            replace = {...data};
        }

        this.#formController.setRenderElement(replace);
    }

    formInput(key, value) {
        this.#formController.input(key, value);
    }
    formClose() {
        this.#modalController.close();
    }
    formResetEditable() {
        let id = this.#formController.getModelData().id;
        this.formReset();
        if(id) {
            this.formInput('id', id);
        }
    }
    formReset() {
        this.#formController.reset();
    }

    submit() {
        const valid = this.#formController.validate();
        if(valid) {
            return this.createOrUpdate();
        }
    }
    initModalLink(getLink) {
        this.#modalController.setLink(getLink);
    }

    // CRUD
    createOrUpdate() {
        let promise;
        const data = this.#formController.getModelData();
        if(data.id) {
            promise = this.#contentLoader.update(data);
        } else {
            delete data.id;
            promise = this.#contentLoader.create(data);
        }
        this.createLoading = true;

        return promise
            .then((response) => {
            if (response.data.success) {
                this.fetch();
                this.#formController.reset();
                this.#modalController.close();
            } else {
                throw new Error();
            }
        })
            .catch(e => {
                app.$store.commit('notifications/error', this.crudErrorMessage);
            })
            .finally(() => {
                this.createLoading = false;
            });
    }
    fetch() {
        this.#contentData.loading = true;
        this.emit('input', ['loading']);
        const filterKeys = this.getFilterStringRequest();
        return this.#contentLoader.fetch(filterKeys)
            .then(data => {
                if (data.success) {
                    this.#contentData.list = data.data;
                    this.#contentData.count = data.count;
                    this.emit('input', ['list', 'count']);
                }
                return data;
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                this.#contentData.loading = false;
                this.emit('input', ['loading']);
            })
    }
    remove(id) {
        const confirm = new ConfirmController()

        confirm.call().then(() => {
            this.#contentLoader.remove(id)
                .then(() => {
                    const skip = this.getValue('skip');
                    const limit = this.getValue('limit');
                    if(skip != null && skip >= this.#contentData.count - 1) {
                        this.fetchPage(Math.min(1, Math.ceil((this.#contentData.count - 1) / limit)));
                    } else {
                        this.fetch();
                    }

                    app.$store.commit("notifications/add", {
                        type: "success",
                        message: "common.successDelete"
                    })
                })
                .catch(() => {
                    app.$store.commit("notifications/error", "errors.failedWhenRemove")
                })
        })
    }
    fetchPage(page) {
        this.setFilterValue('skip', (page - 1) * this.getValue('limit'));

        this.fetch();
    }
}

export class ContentLoader {
    constructor(url) {
        this.url = url
    }
    fetch(filterString) {
        return get(`${this.url}?${filterString}`)
            .then(response => {
                const data = response.data;
                return {
                    success: data.success,
                    data: data.data.data,
                    count: data.data.count,
                    message: data.message,
                    response: data
                }
            });
    }
    transformData(model) {
        return model;
    }
    update(data) {
        data = this.transformData(data);
        return put(`${this.url}/${data.id}`, data);
    }
    create(data) {
        data = this.transformData(data);
        return post(this.url, data);
    }
    remove(id) {
        return del(`${this.url}/${id}`);
    }
}
