<template>
    <div class="app-admin-page application-page">
        <ApplicationHeader name="csi.index" disable-count/>
        <PageLoader v-if="contentLoading"/>
        <template v-else>
            <div class="mb-5-a">
                <div v-for="item in langItems" class="csi-block mb-2-a">
                    <div class="csi-block__label">{{ $t("csi.languages.text") }}</div>
                    <div class="csi-block__input">
                        <AppTextarea :value="model[item.model]" :error="isError(item.model)" size="extra-large" @input="onFormInput(item.model, $event)"/>
                        <div v-if="isError(item.model)" class="application-input-error">{{ errorMessages[item.model][0] }}</div>
                    </div>
                    <div class="csi-block__label">{{ $t("csi.languages.textAfterEvaluation") }}</div>
                    <div class="csi-block__input">
                        <AppTextarea :value="model[item.modelSecond]" :error="isError(item.modelSecond)" size="extra-large" @input="onFormInput(item.modelSecond, $event)"/>
                        <div v-if="isError(item.modelSecond)" class="application-input-error">{{ errorMessages[item.modelSecond][0] }}</div>
                    </div>
                    <div class="csi-block__wrap">
                        <div class="csi-block__wrap">
                            <div class="csi-block__label csi-block__label--horizontal csi-block__label--gray">{{ $t("common.language") }}:</div>
                            <div class="csi-block__wrap">
                                <img class="csi-block__lang-icon" :src="getLangIcon(item.lang)"/>
                                <div class="csi-block__label csi-block__label--horizontal">{{ $t(item.langName) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="csi-filter">
                <ApplicationFilter class="csi-filter__filter" :filter-items="filterItems" :filter="model" @filter="onFormInput"/>
                <AppButton class="csi-filter__button" theme="gray" @click="resetForm">{{ $t("common.cancel") }}</AppButton>
                <LoadingContent class="csi-filter__button-wrap" :loading="submitLoading">
                    <AppButton class="csi-filter__button" :theme="formDirty && 'success-flat'" @click="submitForm">{{ formDirty ? $t('common.update') : $t('common.saved') }}</AppButton>
                </LoadingContent>
            </div>
        </template>
    </div>
</template>
<script>
import ApplicationFilter from "../../components/admin/ApplicationFilter";
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import {
    ModalFormController,
    FormInputElement,
    FormInputList,
} from '../../services/ScriptPage';
import clickaway from '../../directives/clickaway';
import {get, post} from "../../helpers/api";
import PageLoader from "../../components/partials/PageLoader";
import AppTextarea from "../../components/partials/AppTextarea";
import AppSwitch from "../../components/partials/AppSwitch";
import AppButton from "../../components/partials/AppButton";
import LoadingContent from "../../components/partials/LoadingContent";

export default {
    name: "CSI",
    components: {
        LoadingContent,
        AppButton,
        AppSwitch,
        AppTextarea,
        PageLoader,
        ApplicationHeader,
        ApplicationFilter,
    },
    directives: {clickaway},
    data() {
        return {
            formController: new ModalFormController(new FormInputList('1', '', '', '', [
                new FormInputElement('textRU', '', '', '', null, [['required']]),
                new FormInputElement('textUZ', '', '', '', null, [['required']]),
                new FormInputElement('textQQ', '', '', '', null, [['required']]),
                new FormInputElement('thanksRU', '', '', '', null, [['required']]),
                new FormInputElement('thanksUZ', '', '', '', null, [['required']]),
                new FormInputElement('thanksQQ', '', '', '', null, [['required']]),
                new FormInputElement('period', '', '', '', null, [['required']]),
                new FormInputElement('enabled', '', '', ''),
            ])),
            fetchedData: {},
            filterItems: [
                {
                    type: 'drop',
                    name: 'csi.timeoutSendSms',
                    key: 'period',
                    labeled: true,
                    size: 'small',
                    theme: 'light',
                    items: [
                        {
                            name: 'csi.minutes.one',
                            value: 1
                        },
                        {
                            name: 'csi.minutes.two',
                            value: 2
                        },
                        {
                            name: 'csi.minutes.five',
                            value: 5,
                        }, {
                            name: 'csi.minutes.ten',
                            value: 10
                        }
                    ]
                },
                {
                    type: 'switch',
                    name: 'csi.serviceWorker',
                    key: 'enabled',
                    labeled: true,
                }
            ],
            langItems: [],
            contentLoading: false,
            submitLoading: false,
            errorMessages: {},
            model: {},
        }
    },
    computed: {
        formDirty() {
            const modelKeys = Object.keys(this.model);
            return modelKeys.some(k => this.fetchedData[k] !== this.model[k]);
        }
    },
    created() {
        this.fetchSettings();
    },
    mounted() {
        this.model = this.formController.getModelData();
        this.errorMessages = this.formController.getErrorMessages();

        this.formController.on('input', this.onControllerInput);
        this.formController.on('validate', this.onControllerValidate);
    },
    beforeDestroy() {
        this.formController.off('input', this.onControllerInput);
        this.formController.off('validate', this.onControllerValidate);
    },
    methods: {
        onControllerInput() {
            this.model = this.formController.getModelData();
        },
        onControllerValidate() {
            this.errorMessages = this.formController.getErrorMessages();
        },
        getLangIcon(lang) {
            return require(`@/assets/img/${lang}.svg`);
        },
        fetchSettings() {
            this.contentLoading = true;
            get('/ratings/settings')
                .then(({data}) => {
                    this.langItems = [
                        {
                            model: 'textRU',
                            modelSecond: 'thanksRU',
                            lang: 'ru',
                            langName: 'common.languages.ru'
                        },
                        {
                            model: 'textUZ',
                            modelSecond: 'thanksUZ',
                            lang: 'uz',
                            langName: 'common.languages.uz'
                        },
                        {
                            model: 'textQQ',
                            modelSecond: 'thanksQQ',
                            lang: 'qq',
                            langName: 'common.languages.qq'
                        },
                    ];
                    this.fetchedData = data;
                    this.resetForm();
                })
                .catch(e => {
                    this.$store.commit('notifications/error', 'errors.somethingWentWrong');
                })
                .finally(() => {
                    this.contentLoading = false;
                })
        },
        submitForm() {
            if(this.submitLoading) return;
            const valid = this.formController.validate();
            if(valid) {
                this.submitLoading = true;
                post('/ratings/settings', this.model)
                    .then(({data}) => {
                        this.fetchedData = {
                            ...data,
                            enabled: this.model.enabled
                        }
                    })
                    .catch(e => {
                        this.$store.commit('notifications/error', 'errors.somethingWentWrong');
                    })
                    .finally(() => {
                        this.submitLoading = false;
                    })
            }
        },
        resetForm() {
            this.onFormInput('textRU', this.fetchedData.textRU);
            this.onFormInput('textUZ', this.fetchedData.textUZ);
            this.onFormInput('textQQ', this.fetchedData.textQQ);
            this.onFormInput('thanksRU', this.fetchedData.thanksRU);
            this.onFormInput('thanksUZ', this.fetchedData.thanksUZ);
            this.onFormInput('thanksQQ', this.fetchedData.thanksQQ);
            this.onFormInput('enabled', this.fetchedData.enabled);
            this.onFormInput('period', this.fetchedData.period);
        },
        onFormInput(key, value) {
            this.formController.input(key, value);
        },
        isError(key) {
            return this.errorMessages[key] && this.errorMessages[key].length;
        }
    },
    beforeRouteEnter(to, from, next) {
        if (process.env.VUE_APP_HIDE_CSI_SETTING === 'true') {
            next(vm => {
                vm.$router.go(-1);
            });
        } else {
            next()
        }
    },
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.csi-block {
    padding: 34px;
    background: #FFFFFF;
    border-radius: 12px;
    font-family: $font-secondary;
    &__label {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #3C3C3C;
        &--horizontal {
            margin-bottom: 0;
            margin-right: 12px;
        }
        &--gray {
            color: #717171;
        }
    }
    &__input {
        margin-bottom: 24px;
    }
    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__lang-icon {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        border-radius: 100%;
    }
}
.csi-filter {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 24px 34px;
    display: flex;
    align-items: center;
    &__filter {
        margin-right: auto;
    }
    &__button-wrap {
        margin-left: 20px;
    }
    &__button {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}
</style>
