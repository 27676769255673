<template>
    <component :is="tag" class="app-switch" :class="{['app-switch--theme-' + theme]: theme}">
        <input :checked="model" class="app-switch__input" type="checkbox" @change="input">
        <transition v-if="trueValue" name="slide-x" mode="out-in">
            <div :key="value ? trueValue : falseValue" class="app-switch__name">{{ value ? getTranslationOrKey(trueValue) : getTranslationOrKey(falseValue) }}</div>
        </transition>
        <div class="app-switch__figure">
            <div class="app-switch__dot"></div>
        </div>
    </component>
</template>
<script>
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
    name: 'AppSwitch',
    mixins: [translationExists],
    props: {
        value: [Boolean, Array],
        val: [String, Number],
        trueValue: String,
        falseValue: String,
        theme: String,

        tag: {
            type: String,
            default: 'label'
        }
    },
    computed: {
        multiple() {
            return Array.isArray(this.value);
        },
        model() {
            if(this.multiple) {
                return this.value.includes(this.val);
            } else {
                return this.value;
            }
        }
    },
    methods: {
        input() {
            if(this.multiple) {
                if(this.value.includes(this.val)) {
                    this.$emit('input', this.value.filter(f => f != this.val));
                } else {
                    this.$emit('input', [...this.value, this.val]);
                }
            } else {
                this.$emit('input', !this.value);
            }
        }
    }
}
</script>
<style lang="scss">
.app-switch {
    $self: &;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    user-select: none;
    &__input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        &:checked ~ #{$self}__figure {
            background-color: #00CC56;
            #{$self}__dot {
                transform: translateX(20px);
            }
        }
        &:focus ~ #{$self}__figure {
            box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        }
    }
    &__figure {
        transition: background-color .5s, box-shadow .25s;
        flex-shrink: 0;
        width: 44px;
        height: 24px;
        border-radius: 12px;
        background-color: #D0D3D4;
        position: relative;
    }
    &__dot {
        transition: transform .5s;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background-color: #fff;
    }
    &__name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #717171;
        margin-right: 8px;
        text-align: right;
    }
    &--theme-secondary {
        #{$self}__input:checked ~ #{$self}__figure {
            background-color: #50B5FF;
        }
    }
}
</style>
